// extracted by mini-css-extract-plugin
export var btnDark = "video-module--btn-dark--2232e";
export var btnLight = "video-module--btn-light--cb5b0";
export var buttonDefault = "video-module--button-default--fbd47";
export var buttonLarge = "video-module--button-large--f3519";
export var buttonSmall = "video-module--button-small--2fc98";
export var ctaLarge = "video-module--cta-large--95460";
export var ctaSmall = "video-module--cta-small--2574f";
export var highlight = "video-module--highlight--0ff81";
export var input = "video-module--input--0b315";
export var labelLarge = "video-module--label-large--a9137";
export var labelMedium = "video-module--label-medium--4ab63";
export var languages = "video-module--languages--af9e2";
export var link = "video-module--link--0d629";
export var optionContainer = "video-module--optionContainer--d0df5";
export var optionListContainer = "video-module--optionListContainer--3b843";
export var pLarge = "video-module--p-large--41fd2";
export var pLargeBold = "video-module--p-large-bold--f5bbd";
export var pMedium = "video-module--p-medium--8b56e";
export var pSmall = "video-module--p-small--c978f";
export var searchWrapper = "video-module--search-wrapper--f6798";
export var singleChip = "video-module--singleChip--fc03e";
export var slickNext = "video-module--slick-next--44241";
export var slickPrev = "video-module--slick-prev--91247";
export var slickSlide = "video-module--slick-slide--e2375";
export var titleLarge = "video-module--title-large--a2270";
export var titleMedium = "video-module--title-medium--a4b0f";
export var titleSmall = "video-module--title-small--05289";
export var titleXl = "video-module--title-xl--c9d9b";
export var videoContainer = "video-module--video-container--87852";