import * as React from "react";
import * as videoStyles from "../styles/components/video.module.scss";

const Video = ({ title, video }) => {
  const links = {
    Youtube: "https://www.youtube.com/embed/",
    Vimeo: "https://player.vimeo.com/video/",
  };

  const findPlatformLink = `${links[video?.video_platform]}${video?.video_id}`;

  return (
    <section className={videoStyles.videoContainer}>
      <iframe
        className="responsive-iframe"
        src={findPlatformLink}
        title={`${title} Video`}
        width="100%"
        height="100%"
        frameborder="0"
        allow="autoplay; fullscreen; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
        referrerpolicy="no-referrer|no-referrer-when-downgrade"
      />
    </section>
  );
};

export default Video;
