import React, { useState } from "react";
import Layout from "../../components/layout";
import Brandbanner from "../../components/brandbanner";
import BrandCarousel from "../../components/brandcarousel";
import Video from "../../components/video";
import { SEO } from "../../components/seo";
import { graphql, navigate } from "gatsby";
import { getPageByLanguage } from "../../hooks/get-page-by-language";
import useCookie from "../../hooks/use-cookie";
import { getBrowserLanguage } from "../../hooks/get-language";
import { getHeaderAndFooter } from "../../hooks/get-header-and-footer";

const Brand = ({ data, location }) => {
  const [dropdown, setDropdown] = useState({
    key: "",
    name: "",
  });
  const [cookie, setCookie] = useCookie(
    "language",
    getBrowserLanguage(location)
  );

  const { foundHeader, foundFooter } = getHeaderAndFooter(data, cookie);
  let findBrandByName = data.allContentstackBrands.nodes.filter((brand) =>
    location.pathname.includes(brand.url)
  );
  let foundBrandByLocale = getPageByLanguage(findBrandByName, false, cookie);
  let foundSocialMediaLogos = getPageByLanguage(
    data.allContentstackSocialMediaLogos.nodes,
    false,
    cookie
  );

  const isBrowser = typeof window !== "undefined";
  if (!foundBrandByLocale && isBrowser) {
    navigate("/404/");
  }

  return (
    <Layout
      navigation={foundHeader}
      footer={foundFooter}
      dropdown={dropdown}
      setDropdown={setDropdown}
      cookie={cookie}
      setCookie={setCookie}
    >
      <Brandbanner
        brand={foundBrandByLocale}
        logos={foundSocialMediaLogos.logos}
      />
      <Video
        title={foundBrandByLocale?.title}
        video={foundBrandByLocale?.external_video}
      />
      <BrandCarousel carousel={foundBrandByLocale?.brand_carousel} />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentstackHeader {
      nodes {
        locale
        menu {
          link {
            href
            title
          }
        }
        logo {
          logo_title
          logo_image {
            url
          }
        }
      }
    }
    allContentstackFooter {
      nodes {
        locale
        address {
          street
          city_state_zip
          phone_number
          email
        }
        company_motto {
          motto_line_1
          motto_line_2
        }
        copyright
        locale
        title
        uid
        secondary_menu {
          link {
            href
            title
          }
        }
        main_links {
          link {
            href
            title
          }
        }
        logo {
          logo_image {
            url
          }
          logo_title
        }
      }
    }
    allContentstackBrands {
      nodes {
        locale
        title
        brand_carousel {
          title
          link {
            href
            title
          }
          background_image {
            url
            title
          }
          global_field {
            logo_title
            logo_image {
              url
              title
            }
          }
        }
        url
        social_media {
          social_media_brand
          username
        }
        link {
          href
          title
        }
        logo {
          title
          url
        }
        text
        image {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          title
        }
        external_video {
          video_id
          video_platform
        }
      }
    }
    allContentstackSocialMediaLogos {
      nodes {
        locale
        logos {
          logo_title
          logo_image {
            url
            title
          }
        }
      }
    }
  }
`;
export default Brand;
export const Head = () => <SEO title="Elevate Outdoor Collective | Brands" />;
