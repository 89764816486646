// extracted by mini-css-extract-plugin
export var banner = "brandbanner-module--banner--b5f80";
export var bannerContent = "brandbanner-module--banner-content--262af";
export var bannerImageBlock = "brandbanner-module--banner-image-block--58576";
export var btnDark = "brandbanner-module--btn-dark--4d4b5";
export var btnLight = "brandbanner-module--btn-light--226ea";
export var buttonDefault = "brandbanner-module--button-default--722af";
export var buttonLarge = "brandbanner-module--button-large--4920d";
export var buttonSmall = "brandbanner-module--button-small--3ae51";
export var ctaLarge = "brandbanner-module--cta-large--86668";
export var ctaSmall = "brandbanner-module--cta-small--05a1b";
export var highlight = "brandbanner-module--highlight--02302";
export var input = "brandbanner-module--input--63d64";
export var labelLarge = "brandbanner-module--label-large--7d97a";
export var labelMedium = "brandbanner-module--label-medium--dbec8";
export var languages = "brandbanner-module--languages--c9c08";
export var link = "brandbanner-module--link--02b4d";
export var linksContainer = "brandbanner-module--links-container--f207a";
export var mainText = "brandbanner-module--main-text--1cdb2";
export var mainTitle = "brandbanner-module--main-title--763f7";
export var optionContainer = "brandbanner-module--optionContainer--58bae";
export var optionListContainer = "brandbanner-module--optionListContainer--33b37";
export var pLarge = "brandbanner-module--p-large--53eb6";
export var pLargeBold = "brandbanner-module--p-large-bold--e8b49";
export var pMedium = "brandbanner-module--p-medium--aa34a";
export var pSmall = "brandbanner-module--p-small--caf8f";
export var searchWrapper = "brandbanner-module--search-wrapper--89c68";
export var singleChip = "brandbanner-module--singleChip--16261";
export var slickNext = "brandbanner-module--slick-next--77c32";
export var slickPrev = "brandbanner-module--slick-prev--89ce2";
export var slickSlide = "brandbanner-module--slick-slide--af696";
export var titleLarge = "brandbanner-module--title-large--07891";
export var titleMedium = "brandbanner-module--title-medium--ea1ea";
export var titleSmall = "brandbanner-module--title-small--11d93";
export var titleXl = "brandbanner-module--title-xl--c5e1f";