// extracted by mini-css-extract-plugin
export var btnDark = "sociallinks-module--btn-dark--677d0";
export var btnLight = "sociallinks-module--btn-light--83a01";
export var buttonDefault = "sociallinks-module--button-default--3c053";
export var buttonLarge = "sociallinks-module--button-large--38f0e";
export var buttonSmall = "sociallinks-module--button-small--eb467";
export var ctaLarge = "sociallinks-module--cta-large--731f2";
export var ctaSmall = "sociallinks-module--cta-small--6785b";
export var highlight = "sociallinks-module--highlight--7f0d8";
export var input = "sociallinks-module--input--f0ad0";
export var labelLarge = "sociallinks-module--label-large--a6120";
export var labelMedium = "sociallinks-module--label-medium--05c9b";
export var languages = "sociallinks-module--languages--a9eba";
export var link = "sociallinks-module--link--f02a5";
export var optionContainer = "sociallinks-module--optionContainer--258ed";
export var optionListContainer = "sociallinks-module--optionListContainer--2568d";
export var pLarge = "sociallinks-module--p-large--67ff1";
export var pLargeBold = "sociallinks-module--p-large-bold--2b91f";
export var pMedium = "sociallinks-module--p-medium--e7b49";
export var pSmall = "sociallinks-module--p-small--0359a";
export var searchWrapper = "sociallinks-module--search-wrapper--abfff";
export var singleChip = "sociallinks-module--singleChip--6bd77";
export var slickNext = "sociallinks-module--slick-next--747cd";
export var slickPrev = "sociallinks-module--slick-prev--95330";
export var slickSlide = "sociallinks-module--slick-slide--af733";
export var socialLinksContainer = "sociallinks-module--social-links-container--b3f59";
export var titleLarge = "sociallinks-module--title-large--f8606";
export var titleMedium = "sociallinks-module--title-medium--7eebe";
export var titleSmall = "sociallinks-module--title-small--42297";
export var titleXl = "sociallinks-module--title-xl--23b27";