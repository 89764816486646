import * as React from "react";
import * as socialLinksStyles from "../styles/components/sociallinks.module.scss";

const Sociallinks = ({ brand, logos }) => {
  const links = {
    Facebook: "https://www.facebook.com/",
    Instagram: "https://instagram.com/",
    Pinterest: "https://www.pinterest.com/",
    Twitter: "https://twitter.com/",
    Youtube: "https://www.youtube.com",
    TikTok: "https://www.tiktok.com/",
  };

  const socialMediaList = brand?.social_media.map((social) => {
    let findLogo = logos.find(
      (logo) => logo?.logo_title === social.social_media_brand
    );
    return { logo_image: findLogo.logo_image, ...social };
  });

  return (
    <section className={socialLinksStyles.socialLinksContainer}>
      {socialMediaList?.map((social, idx) => (
        <a
          className={socialLinksStyles.link}
          href={`${links[social.social_media_brand]}${social.username}`}
          key={idx}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={social.logo_image.url}
            alt={social.logo_title}
            aria-label={`${social.social_media_brand} Logo`}
            height="24px"
            width="24px"
          />
        </a>
      ))}
    </section>
  );
};

export default Sociallinks;
