import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Sociallinks from "./sociallinks";
import * as brandbannerStyles from "../styles/components/brandbanner.module.scss";
import classNames from "classnames";
import parse from "html-react-parser";

const Brandbanner = ({ brand, logos }) => {
  const brandImage = getImage(brand?.image);
  return (
    <section className={brandbannerStyles.banner}>
      <div className={brandbannerStyles.bannerContent}>
        <div>
          <img
            src={brand?.logo?.url}
            alt={brand?.logo?.title}
            aria-label={`${brand?.title} Logo`}
            height="50px"
          />
          {brand?.title && (
            <h1 className={brandbannerStyles.mainTitle}>{brand.title}</h1>
          )}
          {brand?.text && (
            <p className={classNames(brandbannerStyles.mainText, "p-medium")}>
              {parse(brand.text)}
            </p>
          )}
          <div className={brandbannerStyles.linksContainer}>
            {brand?.link && (
              <a
                href={brand.link.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="button-small btn-light">
                  {brand.link.title}
                </button>
              </a>
            )}
            <Sociallinks brand={brand} logos={logos} />
          </div>
        </div>
      </div>
      <div className={brandbannerStyles.bannerImageBlock}>
        {brand?.image && (
          <GatsbyImage
            image={brandImage}
            height="100%"
            width="100%"
            alt={brand?.image?.title}
            aria-label={`${brand?.title} Banner Image`}
            loading="eager"
          />
        )}
      </div>
    </section>
  );
};

export default Brandbanner;
